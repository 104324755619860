/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  items: null,
  options: null,
  loading: false,
  creating: false,
  updating: false,
  selectedData: false,
  removing: false,
  displayAddDrawer: false,
  displayEditDrawer: false,
}

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_PRODUCTS:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_PRODUCTS:
      return { ...state, displayAddDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload, displayEditDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayEditDrawer: false }
    default:
      return state
  }
}
