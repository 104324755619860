/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  leadArrayCount: null,
  leadslistinfo: null,
  leadnotesinfo: null,
  lead: null,
  lead_id: null,
  searchleads: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  displayAddLeadDrawer: false,
  displayUpdateLeadDrawer: false,
  displayRemoveLeadModal: false,
  displayLeadOwnerModal: false,
  displayNotesDrawer: false,
  displayAccountsConvertModal: false,
  displayFollowupDrawer: false,
}

export default function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddLeadDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddLeadDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.lead, displayUpdateLeadDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateLeadDrawer: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.lead, displayRemoveLeadModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveLeadModal: false }
    case actions.SHOW_LEAD_OWNER:
      return { ...state, selectedData: action.payload.lead, displayLeadOwnerModal: true }
    case actions.HIDE_LEAD_OWNER:
      return { ...state, displayLeadOwnerModal: false }
    case actions.SHOW_NOTES:
      return { ...state, selectedData: action.payload.lead, displayNotesDrawer: true }
    case actions.HIDE_NOTES:
      return { ...state, selectedData: null, displayNotesDrawer: false }
    case actions.SHOW_FOLLOWUP:
      return { ...state, selectedData: action.payload.lead, displayFollowupDrawer: true }
    case actions.HIDE_FOLLOWUP:
      return { ...state, selectedData: null, displayFollowupDrawer: false }
    case actions.SHOW_ACCOUNTS_CONVERT:
      return { ...state, selectedData: action.payload.lead, displayAccountsConvertModal: true }
    case actions.HIDE_ACCOUNTS_CONVERT:
      return { ...state, selectedData: null, displayAccountsConvertModal: false }
    default:
      return state
  }
}
