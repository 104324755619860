import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallenquiry() {
  return apiClient
    .get(`${BASE_URL}/getenquiry`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function showenquiry() {
  return apiClient
    .get(`${BASE_URL}/getsingleenquiry`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function sendemail(payload) {
  return apiClient
    .post(`${BASE_URL}/sendemail`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function enquiryfilter(payload) {
  return apiClient
    .post(`${BASE_URL}/enquiryfilter`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removeenquiry(payload) {
  return apiClient
    .post(`${BASE_URL}/removeenquiry`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
