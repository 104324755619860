import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Button, Select, DatePicker, Input } from 'antd'
import { injectIntl } from 'react-intl'
import NumberInput from 'components/app/common/NumberInput'
import moment from 'moment'
import RenewalsAction from 'redux/renewals/actions'

const { Option } = Select

const mapStateToProps = ({ dispatch, renewals }) => ({
  dispatch,
  renewals,
})

const UpdateDomainName = ({ renewals, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch({
      type: RenewalsAction.UPDATE_DOMAIN_INFO,
      payload: {
        id: renewals?.selectedData?.id,
        expiry_date: values?.currentrenewal.format('YYYY-MM-DD'),
      },
    })
  }

  const [form] = Form.useForm()

  useEffect(() => {
    dispatch({
      type: RenewalsAction.LOAD_DOMAIN,
    })
  }, [dispatch])

  // function disabledDate(current) {
  //   return current > moment().startOf('day')
  // }

  function disabledDatepast(current) {
    return current < moment().startOf('day')
  }

  const dateFormat = 'YYYY-MM-DD'
  const [valueone] = useState(new Date())
  const datevalue = moment(valueone).format('YYYY-MM-DD')

  console.log('renewals', renewals)

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            form={form}
            className="mb-4"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              domain_id: renewals?.selectedData?.domain_id,
              ipaddress: renewals?.selectedData?.ipaddress,
              servertype: renewals?.selectedData?.servertype,
              started_at: moment(renewals?.selectedData?.registered_date).format('YYYY-MM-DD'),
              expiry_date: moment(renewals?.selectedData?.expiry_date).format('YYYY-MM-DD'),
            }}
            hideRequiredMark
          >
            <div className="row">
              <div className="col-6 mt-3">
                <Form.Item
                  name="domain_id"
                  label={formatMessage({ id: 'form.label.DomainName' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.DomainName' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled
                  >
                    {renewals?.domainnames?.map(o => {
                      return (
                        <Option key={o.id} value={o.id}>
                          {o.domainname}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-6 mt-3">
                <Form.Item
                  name="servertype"
                  label={formatMessage({ id: 'form.label.servertype' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.servertype' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled
                  >
                    return (
                    <Option key="AWS" value="AWS">
                      AWS
                    </Option>
                    )
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="ipaddress"
                  label={formatMessage({ id: 'form.label.ipaddress' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <NumberInput
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.ipaddress' })}
                    maxLength={32}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="expiry_date"
                  label={formatMessage({ id: 'form.label.expiry_date' })}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.expiry_date' })}
                    maxLength={32}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="currentrenewal"
                  label={formatMessage({ id: 'form.label.validtytill' })}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    allowClear={false}
                    disabledDate={disabledDatepast}
                    selected={datevalue}
                    format={dateFormat}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={renewals?.creating}
                  >
                    <strong>{formatMessage({ id: 'action.create' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(UpdateDomainName))
