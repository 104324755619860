import React, { useState, Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
// import moment from 'moment'
import { Input, Table, Form, Spin, Drawer, Badge } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectServices, selectedServicesData } from 'redux/services/selectors'
import { selectSettings } from 'redux/settings/selectors'
import { selectUser } from 'redux/user/selectors'
import { getFormattedDateWithTime } from 'utils/parser'
import ServicesAction from 'redux/services/actions'
import AddServices from '../AddServices'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    services: selectServices,
    selectedServices: selectedServicesData,
    settings: selectSettings,
    user: selectUser,
  })

const ViewServicesInfo = ({ services, selectedServices, settings, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const onSearch = value => {
    dispatch({
      type: ServicesAction.SET_STATE,
      payload: { searchservices: value },
    })
  }

  const showCreate = () => {
    console.log('create')
    dispatch({
      type: ServicesAction.SHOW_CREATE,
    })
  }

  const hideCreate = () => {
    dispatch({
      type: ServicesAction.HIDE_CREATE,
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.servicesData' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.viewservicesData.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.business_name' }),
      dataIndex: 'business_name',
      key: 'business_name',
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.service_name' }),
      dataIndex: 'service_name',
      key: 'service_name',
      render: text => (
        <>
          {text === 'crm' && (
            <>
              <Badge color="green" text={formatMessage({ id: 'text.crm' })} />
            </>
          )}
          {text === 'website' && (
            <>
              <Badge color="blue" text={formatMessage({ id: 'text.website' })} />
            </>
          )}
          {text === 'graphicdesign' && (
            <>
              <Badge color="orange" text={formatMessage({ id: 'text.graphicdesign' })} />
            </>
          )}
          {text === 'digitalmarketing' && (
            <>
              <Badge color="red" text={formatMessage({ id: 'text.digitalmarketing' })} />
            </>
          )}
          {text === 'seo' && (
            <>
              <Badge color="cyan" text={formatMessage({ id: 'text.seo' })} />
            </>
          )}
        </>
      ),
    },

    {
      title: formatMessage({ id: 'text.viewservicesData.started_at' }),
      dataIndex: 'started_at',
      key: 'started_at',
      render: text => getFormattedDateWithTime(text),
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.expiry_date' }),
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: text => getFormattedDateWithTime(text),
    },

    {
      title: formatMessage({ id: 'text.viewservicesData.Validity' }),
      dataIndex: 'date_difference',
      key: 'date_difference',
      render: text => <>{`${text} Days Remaining`}</>,
    },

    {
      title: formatMessage({ id: 'text.viewservicesData.username' }),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: formatMessage({ id: 'text.viewservicesData.created_at' }),
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => getFormattedDateWithTime(text),
    },
  ]

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const servicesData = selectedServices?.filter(data => data.accounts_id === userId)

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.user.placeholder.searchServices' })}
              size="large"
              onChange={e => onSearch(e.target.value)}
              allowClear
            />
          </Form.Item>
        </div>
        <div className="col-md-8 text-right">
          <button
            type="button"
            className="btn theme-btn-style"
            onClick={showCreate}
            style={{ float: 'right', marginTop: '2.5px' }}
          >
            <i className="fe fe-plus mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.createServices' })}
          </button>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {servicesData?.length >= 0 ? (
            <Table
              dataSource={servicesData}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>
      <Drawer
        title={formatMessage({ id: 'text.addServices' })}
        placement="right"
        destroyOnClose
        visible={services?.displayAddServicesDrawer}
        onClose={hideCreate}
        width={settings?.isMobileView ? '100%' : '44%'}
      >
        <AddServices loading={services?.creating} />
      </Drawer>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewServicesInfo))
