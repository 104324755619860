import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import EnquiryAction from 'redux/enquiry/actions'
import { Typography } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectEnquiry, selectedEnquiryData } from 'redux/enquiry/selectors'
import ViewEnquiry from './ViewEnquiry'

const { Text } = Typography

const mapStateToProps = () =>
  createStructuredSelector({
    enquiry: selectEnquiry,
    selectedEnquiry: selectedEnquiryData,
  })

const StaffList = ({ selectedEnquiry, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  useEffect(() => {
    dispatch({
      type: EnquiryAction.LOAD_ALL,
    })
  }, [dispatch])

  const enquiryData = selectedEnquiry?.filter(data => data.accounts_id === userId)

  const enquiryCount = enquiryData?.length || 0

  return (
    <div>
      <Helmet title={formatMessage({ id: 'title.helmet.Enquiry' })} />
      <div className="card">
        <div className="card-body">
          <h5 className="mb-4">
            <strong>{formatMessage({ id: 'text.enquiryList' })}</strong>
            &nbsp;&nbsp;
            <Text className="font-size-16 font-weight-bold" code>
              {formatMessage({ id: 'text.totalCount' }, { count: enquiryCount })}
            </Text>
          </h5>
          <ViewEnquiry apires={enquiryData} />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StaffList))
