const actions = {
  SET_STATE: 'services/SET_STATE',

  SHOW_CREATE: 'services/SHOW_CREATE',
  HIDE_CREATE: 'services/HIDE_CREATE',
  ADD_SERVICES: 'services/ADD_SERVICES',
  SHOW_CHANGE_VALIDITY: 'services/SHOW_CHANGE_VALIDITY',
  HIDE_CHNAGE_VALIDITY: 'services/HIDE_CHNAGE_VALIDITY',
  CHANGE_SERVICE_VALIDITY: 'services/CHANGE_SERVICE_VALIDITY',
  SHOW_FOLLOWUP: 'services/SHOW_FOLLOWUP',
  HIDE_FOLLOWUP: 'services/HIDE_FOLLOWUP',
  ADD_SERVICE_FOLLOWUP: 'services/ADD_SERVICE_FOLLOWUP',

  LOAD_ALL: 'services/LOAD_ALL',
}

export default actions
