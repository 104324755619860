/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  servicesData: null,
  searchservices: null,
  displayAddServicesDrawer: false,
  displayChangeValidityDrawer: false,
  displayFollowupDrawer: false,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
}

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddServicesDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddServicesDrawer: false }
    case actions.SHOW_CHANGE_VALIDITY:
      return { ...state, selectedData: action.payload, displayChangeValidityDrawer: true }
    case actions.HIDE_CHNAGE_VALIDITY:
      return { ...state, displayChangeValidityDrawer: false }
    case actions.SHOW_FOLLOWUP:
      return { ...state, selectedData: action.payload, displayFollowupDrawer: true }
    case actions.HIDE_FOLLOWUP:
      return { ...state, selectedData: null, displayFollowupDrawer: false }

    default:
      return state
  }
}
