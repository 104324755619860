const actions = {
  SET_STATE: 'product/SET_STATE',
  LOAD_ALL: 'product/LOAD_ALL',
  ADD_PRODUCTS: 'product/ADD_PRODUCTS',
  HIDE_PRODUCTS: 'product/HIDE_PRODUCTS',
  SAVE_PRODUCT: 'product/SAVE_PRODUCT',
  UPDATE_PRODUCT: 'product/UPDATE_PRODUCT',
  SHOW_UPDATE: 'product/SHOW_UPDATE',
  HIDE_UPDATE: 'product/HIDE_UPDATE',
  GET_PRODUCT_SPECIFICATIONS: 'product/GET_PRODUCT_SPECIFICATIONS',
}

export default actions
