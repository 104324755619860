/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  searchreceipt: null,
  receiptListInfo: null,
  activeProformaList: null,
  outstandingAmount: null,
  displayReceiptDrawer: false,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
}

export default function receiptReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayReceiptDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayReceiptDrawer: false }
    default:
      return state
  }
}
