import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadallproducts, saveproduct, updateproduct, getspecification } from 'api/product'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallproducts)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsList: response?.data?.productsList,
      },
    })
  }
}

export function* GET_PRODUCT_SPECIFICATIONS({ payload }) {
  const { response } = yield call(getspecification, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        options: response?.data?.options,
      },
    })
  }
}

export function* SAVE_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(saveproduct, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsList: response?.data?.productsList,
        displayAddDrawer: false,
      },
    })
  }
}

export function* UPDATE_PRODUCT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateproduct, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsList: response?.data?.productsList,
        displayEditDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.GET_PRODUCT_SPECIFICATIONS, GET_PRODUCT_SPECIFICATIONS),
    takeLatest(actions.SAVE_PRODUCT, SAVE_PRODUCT),
    takeLatest(actions.UPDATE_PRODUCT, UPDATE_PRODUCT),
  ])
}
