import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Button, Select, DatePicker, Input } from 'antd'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import RenewalsAction from 'redux/renewals/actions'

const { Option } = Select

const mapStateToProps = ({ dispatch, renewals }) => ({
  dispatch,
  renewals,
})

const AddDomainName = ({ renewals, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch({
      type: RenewalsAction.SAVE_EMAIL_INFO,
      payload: {
        domain_id: values?.domain_id,
        type: values?.type,
        emailid: values?.emailid,
        registered_date: values?.started_at.format('YYYY-MM-DD'),
        expiry_date: values?.expiry_date.format('YYYY-MM-DD'),
      },
    })
  }

  const [form] = Form.useForm()

  useEffect(() => {
    dispatch({
      type: RenewalsAction.LOAD_DOMAIN,
    })
  }, [dispatch])

  function disabledDate(current) {
    return current > moment().startOf('day')
  }

  function disabledDatepast(current) {
    return current < moment().startOf('day')
  }

  const dateFormat = 'YYYY-MM-DD'
  const [valueone] = useState(new Date())
  const datevalue = moment(valueone).format('YYYY-MM-DD')

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const filteredDomain = renewals?.domainnames?.filter(data => data.accounts_id === userId)

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form form={form} className="mb-4" layout="vertical" onFinish={onFinish} hideRequiredMark>
            <div className="row">
              <div className="col-6 mt-3">
                <Form.Item
                  name="domain_id"
                  label={formatMessage({ id: 'form.label.DomainName' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.DomainName' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {filteredDomain?.map(o => {
                      return (
                        <Option key={o.id} value={o.id}>
                          {o.domainname}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-6 mt-3">
                <Form.Item
                  name="type"
                  label={formatMessage({ id: 'form.label.type' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.type' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    return (
                    <Option key="Gsuite" value="Gsuite">
                      Gsuite
                    </Option>
                    <Option key="Zoho" value="Zoho">
                      Zoho
                    </Option>
                    <Option key="ThirdParty" value="ThirdParty">
                      ThirdParty
                    </Option>
                    )
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="emailid"
                  label={formatMessage({ id: 'form.label.emailid' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.emailid' })}
                    maxLength={32}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="started_at"
                  label={formatMessage({ id: 'form.label.registeredat' })}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    // format="MMM D, YYYY"
                    allowClear={false}
                    disabledDate={disabledDate}
                    selected={datevalue}
                    format={dateFormat}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="expiry_date"
                  label={formatMessage({ id: 'form.label.expiry_date' })}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    allowClear={false}
                    disabledDate={disabledDatepast}
                    selected={datevalue}
                    format={dateFormat}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={renewals?.creating}
                  >
                    <strong>{formatMessage({ id: 'action.create' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(AddDomainName))
