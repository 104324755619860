import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadalldailyreports() {
  return apiClient
    .get(`${BASE_URL}/getdailyreports`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loaduser() {
  return apiClient
    .get(`${BASE_URL}/getdailyreportuser`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savedailyreport(payload) {
  return apiClient
    .post(`${BASE_URL}/createDailyReports`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
