import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadalltasks() {
  return apiClient
    .get(`${BASE_URL}/getalltasks`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveTasks(payload) {
  return apiClient
    .post(`${BASE_URL}/createTasks`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
