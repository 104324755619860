import { createSelector } from 'reselect'

export const selectDomainNameList = state => state?.renewals

export const selectedDomainNameList = createSelector([selectDomainNameList], renewals => {
  const { searchDomainname, domainListINfo } = renewals
  if (searchDomainname && domainListINfo) {
    return domainListINfo.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchDomainname.toLowerCase()) ||
        r?.domainname?.toLowerCase().includes(searchDomainname.toLowerCase()) ||
        r?.username?.toLowerCase().includes(searchDomainname.toLowerCase()) ||
        r?.expiry_date?.toLowerCase().includes(searchDomainname.toLowerCase()) ||
        r?.registered_date?.toLowerCase().includes(searchDomainname.toLowerCase()),
    )
  }
  return domainListINfo
})

export const selectedHostingList = createSelector([selectDomainNameList], renewals => {
  const { searchhosting, hostingListINfo } = renewals
  if (searchhosting && hostingListINfo) {
    return hostingListINfo.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchhosting.toLowerCase()) ||
        r?.domainname?.toLowerCase().includes(searchhosting.toLowerCase()) ||
        r?.username?.toLowerCase().includes(searchhosting.toLowerCase()) ||
        r?.servertype?.toLowerCase().includes(searchhosting.toLowerCase()) ||
        r?.expiry_date?.toLowerCase().includes(searchhosting.toLowerCase()) ||
        r?.registered_date?.toLowerCase().includes(searchhosting.toLowerCase()),
    )
  }
  return hostingListINfo
})

export const SelectedEmailList = createSelector([selectDomainNameList], renewals => {
  const { searchemail, emailListINfo } = renewals
  if (searchemail && emailListINfo) {
    return emailListINfo.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchemail.toLowerCase()) ||
        r?.domainname?.toLowerCase().includes(searchemail.toLowerCase()) ||
        r?.username?.toLowerCase().includes(searchemail.toLowerCase()) ||
        r?.type?.toLowerCase().includes(searchemail.toLowerCase()) ||
        r?.expiry_date?.toLowerCase().includes(searchemail.toLowerCase()) ||
        r?.registered_date?.toLowerCase().includes(searchemail.toLowerCase()),
    )
  }
  return emailListINfo
})
