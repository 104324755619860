import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import InvoiceAction from 'redux/invoice/actions'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
import ProformaInvoiceComp from './ProformaInvoice'
import InvoiceComp from './Invoice'

const { TabPane } = Tabs

const mapStateToProps = ({ dispatch, invoice }) => ({
  dispatch,
  invoice,
})

const ManageInvoicesComponent = ({ invoice, dispatch, intl: { formatMessage } }) => {
  const proformaname = 'PO'
  const invoicename = 'IN'

  const path = window.location.pathname

  const userId = path.replace('/manage/accounts/', '')

  const apidata = invoice?.invoicelist
  const proformaInvoiceData = apidata?.filter(
    data => data.type === proformaname && data.accounts_id === userId,
  )

  const proformaCount = proformaInvoiceData?.length || 0

  const InvoiceData = apidata?.filter(
    data => data.type === invoicename && data.accounts_id === userId,
  )
  const invoiceCount = InvoiceData?.length || 0

  useEffect(() => {
    dispatch({
      type: InvoiceAction.LOAD_ALL,
    })
  }, [dispatch])

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Tabs defaultActiveKey={1}>
            <TabPane
              tab={
                <Link>
                  <span>
                    {formatMessage({ id: 'text.store.tab.proformaInvoice' })}
                    {' ('}
                    {formatMessage({ id: 'text.space' }, { count: proformaCount })}
                    {')'}{' '}
                  </span>
                </Link>
              }
              key={1}
            >
              <ProformaInvoiceComp data={proformaInvoiceData} />
            </TabPane>
            <TabPane
              tab={
                <Link>
                  <span>
                    {formatMessage({ id: 'text.store.tab.Invoice' })}
                    {' ('}
                    {formatMessage({ id: 'text.space' }, { count: invoiceCount })}
                    {')'}{' '}
                  </span>
                </Link>
              }
              key={2}
            >
              <InvoiceComp data={InvoiceData} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ManageInvoicesComponent))
