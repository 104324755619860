const actions = {
  SET_STATE: 'quotation/SET_STATE',
  SHOW_FOLLOWUP: 'quotation/SHOW_FOLLOWUP',
  HIDE_FOLLOWUP: 'quotation/HIDE_FOLLOWUP',
  SAVE_FOLLOWUP: 'quotation/SAVE_FOLLOWUP',

  SHOW_DELETE: 'quotation/SHOW_DELETE',
  HIDE_DELETE: 'quotation/HIDE_DELETE',
  DELETE_QUOTATION: 'quotation/DELETE_QUOTATION',

  LOAD_ALL: 'quotation/LOAD_ALL',
  CREATE_QUOTATION: 'quotation/CREATE_QUOTATION',
  HIDE_QUOTATION: 'quotation/HIDE_QUOTATION',
  SHOW_ORDER_DETAIL: 'quotation/SHOW_ORDER_DETAIL',
  HIDE_ORDER_DETAIL: 'quotation/HIDE_ORDER_DETAIL',
  GET_SPECIFICATION: 'quotation/GET_SPECIFICATION',
  HIDE_SPECIFICATION: 'quotation/HIDE_SPECIFICATION',
  LOAD_PRODUCT_INFO: 'quotation/LOAD_PRODUCT_INFO',
  EXPORT_PDF: 'quotation/EXPORT_PDF',
  SAVE_QUOTATION: 'quotation/SAVE_QUOTATION',
  SHOW_UPDATE_QUOTATION: 'quotation/SHOW_UPDATE_QUOTATION',
  HIDE_UPDATE_QUOTATION: 'quotation/HIDE_UPDATE_QUOTATION',
  LOAD_SINGLE_QUOTATION: 'quotation/LOAD_SINGLE_QUOTATION',
  UPDATE_QUOTAION: 'quotation/UPDATE_QUOTAION',
  LOAD_SINGLE_ACCOUNTS: 'quotation/LOAD_SINGLE_ACCOUNTS',
  DESIGN_INFO: 'quotation/DESIGN_INFO',
}

export default actions
