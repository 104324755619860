import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadallquotation,
  getspecificationinfo,
  loadproductinfo,
  downloadpdf,
  savequotation,
  loadquotation,
  updatequotation,
  savefollowup,
  deletequotation,
} from 'api/quotation'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallquotation)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationlistinfo: response?.data?.quotationlist,
      },
    })
  }
}

export function* LOAD_SINGLE_QUOTATION({ payload }) {
  const { response } = yield call(loadquotation, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationRecord: response?.data?.quotationRecord,
      },
    })
  }
}

export function* LOAD_PRODUCT_INFO({ payload }) {
  const { response } = yield call(loadproductinfo, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productlistInfo: response?.data?.productlistInfo,
      },
    })
  }
}

export function* GET_SPECIFICATION({ payload }) {
  const { response } = yield call(getspecificationinfo, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        specificationInfo: response?.data?.specificationInfo,
        displaySpecificationDrawer: true,
      },
    })
  }
}

export function* EXPORT_PDF({ payload }) {
  const { response } = yield call(downloadpdf, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        pdfLocationUrl: response?.data?.pdfLocationUrl,
      },
    })
  }
}

export function* SAVE_QUOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: true,
    },
  })
  const { response } = yield call(savequotation, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_QUOTATION,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationlistinfo: response?.data?.quotationlist,
      },
    })
  }
}

export function* DELETE_QUOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(deletequotation, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationlistinfo: response?.data?.quotationlist,
      },
    })
  }
}

export function* SAVE_FOLLOWUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: true,
    },
  })
  const { response } = yield call(savefollowup, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_FOLLOWUP,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayFollowupModal: false,
        FollowupListInfo: response?.data?.FollowupListInfo,
      },
    })
  }
}

export function* UPDATE_QUOTAION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatequotation, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE_QUOTATION,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationlistinfo: response?.data?.quotationlist,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.DELETE_QUOTATION, DELETE_QUOTATION),
    takeLatest(actions.SAVE_FOLLOWUP, SAVE_FOLLOWUP),
    takeLatest(actions.GET_SPECIFICATION, GET_SPECIFICATION),
    takeLatest(actions.LOAD_PRODUCT_INFO, LOAD_PRODUCT_INFO),
    takeLatest(actions.EXPORT_PDF, EXPORT_PDF),
    takeLatest(actions.SAVE_QUOTATION, SAVE_QUOTATION),
    takeLatest(actions.LOAD_SINGLE_QUOTATION, LOAD_SINGLE_QUOTATION),
    takeLatest(actions.UPDATE_QUOTAION, UPDATE_QUOTAION),
  ])
}
