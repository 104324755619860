import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Form, Button, Select, DatePicker } from 'antd'
import { injectIntl } from 'react-intl'
import AccountsAction from 'redux/accounts/actions'
import moment from 'moment'
import ServicesAction from 'redux/services/actions'

const { Option } = Select

const mapStateToProps = ({ dispatch, accounts, services }) => ({
  dispatch,
  accounts,
  services,
})

const CreateServices = ({ accounts, services, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const onFinish = values => {
    dispatch({
      type: ServicesAction.ADD_SERVICES,
      payload: {
        accounts_id: values?.accounts_id,
        name: values?.name,
        started_at: values?.started_at.format('YYYY-MM-DD'),
        validity: values?.validity,
      },
    })
  }

  const [form] = Form.useForm()

  useEffect(() => {
    dispatch({
      type: AccountsAction.LOAD_ALL,
    })
  }, [dispatch])

  function disabledDate(current) {
    return current > moment().startOf('day')
  }

  const dateFormat = 'YYYY-MM-DD'
  const [valueone] = useState(new Date())
  const datevalue = moment(valueone).format('YYYY-MM-DD')

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form form={form} className="mb-4" layout="vertical" onFinish={onFinish} hideRequiredMark>
            <div className="row">
              <div className="col-12 mt-3">
                <Form.Item
                  name="accounts_id"
                  label={formatMessage({ id: 'form.label.BusinessName' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.BusinessName' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {accounts?.accountlistinfo?.map(o => {
                      return (
                        <Option key={o.id} value={o.id}>
                          {o.business_name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="name"
                  label={formatMessage({ id: 'form.label.serviceName' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.serviceName' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    return (
                    <Option key="website" value="website">
                      Website Hosting
                    </Option>
                    <Option key="crm" value="crm">
                      CRM
                    </Option>
                    <Option key="graphicdesign" value="graphicdesign">
                      Graphic Design
                    </Option>
                    <Option key="digitalmarketing" value="digitalmarketing">
                      Digital Marketing
                    </Option>
                    <Option key="seo" value="seo">
                      SEO
                    </Option>
                    )
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item name="started_at" label={formatMessage({ id: 'form.label.started_at' })}>
                  <DatePicker
                    style={{ width: '100%' }}
                    size="large"
                    // format="MMM D, YYYY"
                    allowClear={false}
                    disabledDate={disabledDate}
                    selected={datevalue}
                    format={dateFormat}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  name="validity"
                  label={formatMessage({ id: 'form.label.validity' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'form.placeholder.validity' })}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    return (
                    <Option key="1" value="1">
                      1 Year
                    </Option>
                    <Option key="2" value="2">
                      2 Years
                    </Option>
                    <Option key="3" value="3">
                      3 Years
                    </Option>
                    <Option key="4" value="4">
                      4 Years
                    </Option>
                    <Option key="5" value="5">
                      5 Years
                    </Option>
                    )
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-12">
                <Form.Item className="text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={services?.creating}
                  >
                    <strong>{formatMessage({ id: 'action.create' })}</strong>
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(CreateServices))
