import React, { useState, Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
// import moment from 'moment'
import { Input, Table, Form, Spin } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectEnquiry, selectedEnquiryData } from 'redux/enquiry/selectors'
import EnquiryAction from 'redux/enquiry/actions'
import EmailConfirmationModal from '../EmailConfirmation'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    enquiry: selectEnquiry,
    EnquiryInfo: selectedEnquiryData,
  })

const ViewLeads = ({ EnquiryInfo, enquiry, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const selectedEnquiry = EnquiryInfo?.filter(data => data.accounts_id === userId)

  const onSearch = value => {
    dispatch({
      type: EnquiryAction.SET_STATE,
      payload: { searchenquiry: value },
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.enquiryData' }),
  }

  const ShowsendEmail = values => {
    dispatch({
      type: EnquiryAction.SHOW_SEND_EMAIL_MODAL,
      payload: {
        enquiry: values,
      },
    })
  }

  const HideSendEmail = () => {
    dispatch({
      type: EnquiryAction.HIDE_SEND_EMAIL_MODAL,
    })
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.viewEnquiry.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.viewEnquiry.name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: formatMessage({ id: 'text.viewEnquiry.email' }),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: formatMessage({ id: 'text.viewEnquiry.mobile' }),
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: formatMessage({ id: 'text.viewEnquiry.subject' }),
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: formatMessage({ id: 'text.viewEnquiry.website' }),
      dataIndex: 'website',
      key: 'website',
    },
    {
      title: formatMessage({ id: 'text.viewEnquiry.enquiry_date' }),
      dataIndex: ['enquiry_date'],
      key: 'enquiry_date',
    },
    {
      title: formatMessage({ id: 'text.action' }),
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <>
          <button
            type="button"
            className="btn btn-primary"
            title="Send Email"
            onClick={() => ShowsendEmail(record)}
          >
            <i className="fe fe-edit mr-1" aria-hidden />
            {formatMessage({ id: 'action.sendEmail' })}
          </button>
        </>
      ),
    },
  ]

  console.log('enquiry', enquiry)

  return (
    <div className="mb-4">
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.user.placeholder.searchEnquiry' })}
              size="large"
              onChange={e => onSearch(e.target.value)}
              allowClear
            />
          </Form.Item>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {selectedEnquiry?.length >= 0 ? (
            <Table
              dataSource={selectedEnquiry}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>
      <EmailConfirmationModal
        visible={enquiry?.displaySendEmailModal}
        title={`Enquiry - ${enquiry?.selectedData?.business_name}`}
        onCancel={HideSendEmail}
        description={formatMessage({ id: 'text.sendEmailDescription' })}
        loading={enquiry?.sending}
      />
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ViewLeads))
