import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loaddomainlist,
  loadhostinglist,
  savedomainname,
  updatedomainname,
  domainlist,
  savehosting,
  emaillist,
  saveemaillist,
  updateemaillist,
  updatehosting,
} from 'api/renewals'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loaddomainlist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        domainListINfo: response?.data?.domainListINfo,
      },
    })
  }
}

export function* LOAD_ALL_HOSTING() {
  const { response } = yield call(loadhostinglist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        hostingListINfo: response?.data?.hostingListINfo,
      },
    })
  }
}

export function* LOAD_DOMAIN() {
  const { response } = yield call(domainlist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        domainnames: response?.data?.domainnames,
      },
    })
  }
}

export function* SAVE_DOMAIN_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(savedomainname, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* SAVE_HOSTING_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(savehosting, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
      displayAddHostingDrawer: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL_HOSTING)
  }
}

export function* UPDATE_HOSTING_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(updatehosting, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
      displayUpdateHostingDrawer: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL_HOSTING)
  }
}

export function* UPDATE_DOMAIN_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatedomainname, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
      displayUpdateDomainNameDrawer: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* LOAD_EMAIL_LIST() {
  const { response } = yield call(emaillist)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        emailListINfo: response?.data?.emailListINfo,
      },
    })
  }
}

export function* SAVE_EMAIL_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(saveemaillist, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
      displayAddEmailDrawer: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_EMAIL_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(updateemaillist, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
      displayUpdateEmailDrawer: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_EMAIL_LIST)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LOAD_DOMAIN, LOAD_DOMAIN),
    takeLatest(actions.LOAD_EMAIL_LIST, LOAD_EMAIL_LIST),
    takeLatest(actions.LOAD_ALL_HOSTING, LOAD_ALL_HOSTING),
    takeLatest(actions.UPDATE_DOMAIN_INFO, UPDATE_DOMAIN_INFO),
    takeLatest(actions.SAVE_HOSTING_INFO, SAVE_HOSTING_INFO),
    takeLatest(actions.SAVE_DOMAIN_INFO, SAVE_DOMAIN_INFO),
    takeLatest(actions.SAVE_EMAIL_INFO, SAVE_EMAIL_INFO),
    takeLatest(actions.UPDATE_EMAIL_INFO, UPDATE_EMAIL_INFO),
    takeLatest(actions.UPDATE_HOSTING_INFO, UPDATE_HOSTING_INFO),
  ])
}
