import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import leads from './leads/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import staff from './staff/reducers'
import accounts from './accounts/reducers'
import invoice from './invoice/reducers'
import enquiry from './enquiry/reducers'
import dailyreports from './dailyreports/reducers'
import renewals from './renewals/reducers'
import services from './services/reducers'
import singleaccounts from './singleaccounts/reducers'
import dashboard from './dashboard/reducers'
import receipt from './receipt/reducers'
import tasks from './tasks/reducers'
import product from './product/reducers'
import quotation from './quotation/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    leads,
    user,
    menu,
    settings,
    staff,
    accounts,
    invoice,
    enquiry,
    dailyreports,
    renewals,
    services,
    singleaccounts,
    dashboard,
    receipt,
    tasks,
    product,
    quotation,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
