import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadallleads,
  saveleads,
  editleads,
  deleteleads,
  changeleadowner,
  loadleadowners,
  loadleadnotes,
  saveleadnote,
  converttoaccounts,
  loadleadfilter,
  addleadfollowup,
} from 'api/lead'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadallleads)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadslistinfo: response?.data?.leadlist,
      },
    })
  }
}

export function* LEAD_FILTER({ payload }) {
  const { response } = yield call(loadleadfilter, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadslistinfo: response?.data?.filteredLeads,
      },
    })
  }
}

export function* ADD_LEAD_FOLLOWUP({ payload }) {
  const { response } = yield call(addleadfollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadfollowupinfo: response?.data?.leadfollowupinfo,
        displayFollowupDrawer: false,
      },
    })
  }
}

export function* GET_LEAD_OWNERS() {
  const { response } = yield call(loadleadowners)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadOwnerData: response?.data?.leadownerslist,
      },
    })
  }
}

export function* GETLEADNOTES({ payload }) {
  const { response } = yield call(loadleadnotes, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadnotesinfo: response?.data?.leadnotesinfo,
      },
    })
  }
}

export function* SAVELEADNOTE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(saveleadnote, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_NOTES,
    })
  }
}

export function* CONVERT_ACCOUNTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(converttoaccounts, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_ACCOUNTS_CONVERT,
    })
    yield call(LOAD_ALL)
  }
}

export function* ADD_LEADS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(saveleads, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_LEADS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { _id, updatedData } = payload
  const { response } = yield call(editleads, _id, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadslistinfo: response?.data?.leadlist,
      },
    })
  }
}

export function* CHANGE_LEAD_OWNER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(changeleadowner, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_LEAD_OWNER,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadslistinfo: response?.data?.leadlist,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(deleteleads, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadslistinfo: response?.data?.leadlist,
      },
    })
  }
  yield call(LOAD_ALL)
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.ADD_LEAD_FOLLOWUP, ADD_LEAD_FOLLOWUP),
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LEAD_FILTER, LEAD_FILTER),
    takeLatest(actions.UPDATE_LEADS, UPDATE_LEADS),
    takeLatest(actions.ADD_LEADS, ADD_LEADS),
    takeLatest(actions.REMOVE, REMOVE),
    takeLatest(actions.GET_LEAD_OWNERS, GET_LEAD_OWNERS),
    takeLatest(actions.GETLEADNOTES, GETLEADNOTES),
    takeLatest(actions.SAVELEADNOTE, SAVELEADNOTE),
    takeLatest(actions.CHANGE_LEAD_OWNER, CHANGE_LEAD_OWNER),
    takeLatest(actions.CONVERT_ACCOUNTS, CONVERT_ACCOUNTS),
  ])
}
