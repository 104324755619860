const actions = {
  SET_STATE: 'receipt/SET_STATE',
  LOAD_ALL: 'receipt/LOAD_ALL',
  LOAD_ACTIVE_PROFORMA: 'receipt/LOAD_ACTIVE_PROFORMA',
  SAVE_RECEIPT: 'receipt/SAVE_RECEIPT',
  SHOW_CREATE: 'receipt/SHOW_CREATE',
  HIDE_CREATE: 'receipt/HIDE_CREATE',
  GET_INVOICE_DETAILS: 'receipt/GET_INVOICE_DETAILS',
  GET_OUTSTANDING: 'receipt/GET_OUTSTANDING',
}

export default actions
