import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadall, savereceipt, loadactiveproforma, getproforma, getoutstanding } from 'api/receipt'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadall)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        receiptListInfo: response?.data?.receiptListInfo,
      },
    })
  }
}

export function* LOAD_ACTIVE_PROFORMA() {
  const { response } = yield call(loadactiveproforma)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        activeProformaList: response?.data?.activeProformaList,
      },
    })
  }
}

export function* SAVE_RECEIPT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(savereceipt, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* GET_INVOICE_DETAILS({ payload }) {
  console.log('payload', payload)
  const { response } = yield call(getproforma, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        paymentInfo: response?.data?.paymentInfo,
      },
    })
  }
}

export function* GET_OUTSTANDING({ payload }) {
  console.log('payload', payload)
  const { response } = yield call(getoutstanding, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        outstandingAmount: Number(response?.data?.outstandingAmount[0]?.cost),
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.SAVE_RECEIPT, SAVE_RECEIPT),
    takeLatest(actions.LOAD_ACTIVE_PROFORMA, LOAD_ACTIVE_PROFORMA),
    takeLatest(actions.GET_INVOICE_DETAILS, GET_INVOICE_DETAILS),
    takeLatest(actions.GET_OUTSTANDING, GET_OUTSTANDING),
  ])
}
