/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  designInfo: null,
  redProductArray: null,
  redbusinessId: null,
  QuotationInfo: null,
  FollowupListInfo: null,
  redcgst: null,
  redsgst: null,
  redigst: null,
  redtaxtype: null,
  redvalidity: null,
  reddelivery: null,
  redwarranty: null,
  redpayment: null,
  redfreight: null,
  rednote: null,
  redproducts: null,
  quotationRecord: null,
  getsinglebusiness: null,
  businessName: null,
  productDetail: [],
  productArray: [],
  _id: null,
  specificationInfo: null,
  quotationlistinfo: null,
  productlistInfo: null,
  pdfLocationUrl: null,
  itemList: null,
  loading: false,
  creating: false,
  updating: false,
  selectedData: false,
  removing: false,
  displayOrderDetailDrawer: false,
  displaySpecificationDrawer: false,
  displayAddLeadDrawer: false,
  displayCreateQuotationDrawer: false,
  displayUpdateQuotationDrawer: false,
  displayFollowupModal: false,
  displayQuotationDeleteModal: false,
}

export default function quotationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.GET_SPECIFICATION:
      return { ...state, selectedData: action.payload, displaySpecificationDrawer: true }
    case actions.SHOW_ORDER_DETAIL:
      return { ...state, selectedData: action.payload, displayOrderDetailDrawer: true }
    case actions.HIDE_ORDER_DETAIL:
      return { ...state, selectedData: null, displayOrderDetailDrawer: false }
    case actions.HIDE_SPECIFICATION:
      return { ...state, selectedData: null, displaySpecificationDrawer: false }
    case actions.CREATE_QUOTATION:
      return { ...state, displayCreateQuotationDrawer: true }
    case actions.HIDE_QUOTATION:
      return { ...state, displayCreateQuotationDrawer: false }
    case actions.SHOW_UPDATE_QUOTATION:
      return { ...state, selectedData: action.payload, displayUpdateQuotationDrawer: true }
    case actions.HIDE_UPDATE_QUOTATION:
      return { ...state, displayUpdateQuotationDrawer: false }
    case actions.SHOW_FOLLOWUP:
      return { ...state, selectedData: action.payload, displayFollowupModal: true }
    case actions.HIDE_FOLLOWUP:
      return { ...state, displayFollowupModal: false }

    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload, displayQuotationDeleteModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayQuotationDeleteModal: false }

    default:
      return state
  }
}
