import { createSelector } from 'reselect'

export const selectDailyReport = state => state?.dailyreports

export const selectedDailyReportData = createSelector([selectDailyReport], dailyreports => {
  const { searchdailyreport, dailyReportData } = dailyreports
  if (searchdailyreport && dailyReportData) {
    return dailyReportData.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchdailyreport.toLowerCase()) ||
        r?.description?.toLowerCase().includes(searchdailyreport.toLowerCase()) ||
        r?.subject?.toLowerCase().includes(searchdailyreport.toLowerCase()) ||
        r?.username?.toLowerCase().includes(searchdailyreport.toLowerCase()),
    )
  }
  return dailyReportData
})
