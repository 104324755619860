import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadall() {
  return apiClient
    .get(`${BASE_URL}/getreceiptlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadactiveproforma() {
  return apiClient
    .get(`${BASE_URL}/getactiveproforma`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savereceipt(payload) {
  return apiClient
    .post(`${BASE_URL}/savereceipt`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getproforma(payload) {
  return apiClient
    .get(`${BASE_URL}/getproformadetails/${payload?.accountsId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getoutstanding(payload) {
  return apiClient
    .get(`${BASE_URL}/getoutstanding/${payload?.paymentsid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
