import React, { useState, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { Form, Input, Table, Spin } from 'antd'
import InvoiceAction from 'redux/invoice/actions'
// import { selectSettings } from 'redux/settings/selectors'
import { getFormattedDate } from 'utils/parser'
// import CreateInvoiceItemComp from '../CreateInvoiceItem'

const { Search } = Input

const mapStateToProps = ({ dispatch, invoice }) => ({
  dispatch,
  invoice,
})

const PaymentHistory = ({ dispatch, data, invoice }) => {
  const { formatMessage, formatNumber } = useIntl()
  const [page, setPage] = useState(1)
  // const settings = useSelector(selectSettings)
  const filterProformaInvoiceData = data

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.businessName' }),
      dataIndex: ['business_name'],
      key: 'business_name',
    },
    {
      title: formatMessage({ id: 'text.invoice_number' }),
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      render: (text, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div role="button" className="font-weight-bold" onClick={() => showInvoice(record)}>
          <span style={{ color: 'red !important' }}>{`AMIV-${record?.invoice_number}`}</span>
        </div>
      ),
    },
    {
      title: formatMessage({ id: 'text.invoiceAmount' }),
      dataIndex: 'total',
      key: 'total',
      render: (record, text) => {
        const totalval = text.total
        const discountval = text.discount
        const totalres = totalval - discountval
        const cgstval = text.cgst
        const cgstres = (totalres * cgstval) / 100
        const sgstval = text.sgst
        const sgstres = (totalres * sgstval) / 100
        const igstval = text.igst
        const igstres = (totalres * igstval) / 100
        const totalResult = Number(totalres) + Number(cgstres) + Number(sgstres) + Number(igstres)

        return (
          <span>
            {formatNumber(totalResult, {
              style: 'currency',
              currency: 'INR',
            })}
          </span>
        )
      },
    },
    {
      title: formatMessage({ id: 'text.invoiceDate' }),
      dataIndex: 'created_date',
      key: 'created_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.createdby' }),
      dataIndex: 'createdby',
      key: 'createdby',
    },
    {
      title: formatMessage({ id: 'text.Action' }),
      key: 'url',
      render: text => (
        <>
          {text.url !== '' && (
            <>
              <button
                type="button"
                className="btn btn-light"
                title="Delete Lead"
                onClick={() => showpdfdocs(text.url)}
              >
                <i className="fe fe-download mr-1" aria-hidden />
              </button>
            </>
          )}
        </>
      ),
    },
  ]

  const showpdfdocs = value => {
    window.open(`https://api.acculermedia.com/${value}`)
  }

  const onSearch = value => {
    dispatch({
      type: InvoiceAction.SET_STATE,
      payload: { searchinvoice: value },
    })
  }

  const showInvoice = value => {
    dispatch({
      type: InvoiceAction.VIEW_INVOICES,
      payload: {
        invoice_id: value?.id,
      },
    })
  }

  // const CreateItem = () => {
  //   dispatch({
  //     type: InvoiceAction.ADD_ITEM,
  //   })
  // }
  // const hideitem = () => {
  //   dispatch({
  //     type: InvoiceAction.HIDE_ITEM,
  //   })
  // }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.invoicelist' }),
  }

  return (
    <div style={{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.placeholder.search.invoice' })}
              size="large"
              onSearch={onSearch}
              defaultValue={invoice?.searchinvoice}
              allowClear
            />
          </Form.Item>
        </div>
        {/* <div className="col-md-8 text-right">
          <button
            type="button"
            className="btn theme-btn-style"
            onClick={CreateItem}
            style={{ float: 'right', marginTop: '2.5px' }}
          >
            <i className="fe fe-plus mr-1" aria-hidden="true" />
            {formatMessage({ id: 'action.createInvoice' })}
          </button>
        </div> */}
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {filterProformaInvoiceData?.length >= 0 ? (
            <Table
              dataSource={filterProformaInvoiceData}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>
      </div>

      {/* <Drawer
        title={formatMessage({ id: 'text.createInvoice' })}
        placement="right"
        destroyOnClose
        visible={invoice.displayCreateInvoiceDrawer}
        onClose={hideitem}
        width={settings.isMobileView ? '100%' : '60%'}
      >
        <CreateInvoiceItemComp />
      </Drawer> */}
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(PaymentHistory))
