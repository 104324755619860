import { createSelector } from 'reselect'

export const selectEnquiry = state => state?.enquiry

export const selectedEnquiryData = createSelector([selectEnquiry], enquiry => {
  const { searchenquiry, enquiryData } = enquiry
  if (searchenquiry && enquiryData) {
    return enquiryData.filter(
      r =>
        r?.name?.toLowerCase().includes(searchenquiry.toLowerCase()) ||
        r?.mobile?.toLowerCase().includes(searchenquiry.toLowerCase()) ||
        r?.website?.toLowerCase().includes(searchenquiry.toLowerCase()) ||
        r?.message?.toLowerCase().includes(searchenquiry.toLowerCase()) ||
        r?.email?.toLowerCase().includes(searchenquiry.toLowerCase()),
    )
  }
  return enquiryData
})
