import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import ServicesAction from 'redux/services/actions'
import { Typography } from 'antd'
import { createStructuredSelector } from 'reselect'
import { selectServices, selectedServicesData } from 'redux/services/selectors'
import ViewFiles from './ViewFiles'

const { Text } = Typography

const mapStateToProps = () =>
  createStructuredSelector({
    services: selectServices,
    selectedServices: selectedServicesData,
  })

const DailyReports = ({ selectedServices, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: ServicesAction.LOAD_ALL,
    })
  }, [dispatch])

  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const servicesData = selectedServices?.filter(data => data.accounts_id === userId)

  const servicesCount = servicesData?.length || 0

  return (
    <div>
      <Helmet title={formatMessage({ id: 'title.helmet.services' })} />
      <div className="card">
        <div className="card-body">
          <h5 className="mb-4">
            <strong>{formatMessage({ id: 'text.servicesList' })}</strong>
            &nbsp;&nbsp;
            <Text className="font-size-16 font-weight-bold" code>
              {formatMessage({ id: 'text.totalCount' }, { count: servicesCount })}
            </Text>
          </h5>
          <ViewFiles />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(DailyReports))
