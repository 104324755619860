const actions = {
  SET_STATE: 'enquiry/SET_STATE',

  SHOW_SEND_EMAIL_MODAL: 'enquiry/SHOW_SEND_EMAIL_MODAL',
  HIDE_SEND_EMAIL_MODAL: 'enquiry/HIDE_SEND_EMAIL_MODAL',
  LOAD_ALL: 'enquiry/LOAD_ALL',
  SEND_EMAIL: 'enquiry/SEND_EMAIL',
  ENQUIRY_FILTER: 'enquiry/ENQUIRY_FILTER',
  SHOW_DELETE: 'enquiry/SHOW_DELETE',
  HIDE_DELETE: 'enquiry/HIDE_DELETE',
  REMOVE: 'enquiry/REMOVE',
}

export default actions
