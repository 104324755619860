export default async function getMenuData() {
  return [
    // {
    //   category: true,
    //   title: 'Leads',
    // },

    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-settings',
      url: '/dashboard',
    },
    {
      category: true,
      title: 'Accounts',
      key: 'accounts',
    },
    {
      title: 'Leads',
      key: 'leads',
      icon: 'fe fe-user',
      url: '/leads',
    },

    {
      title: 'Customers',
      key: 'customers',
      icon: 'fe fe-file',
      url: '/customers',
    },
    {
      category: true,
      title: 'User',
      key: 'user',
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fe fe-user',
      url: '/user',
    },
    // {
    //   title: 'Manage Tasks',
    //   key: 'manage-tasks',
    //   icon: 'fe fe-settings',
    //   url: '/manage/tasks',
    // },
    {
      category: true,
      title: 'Quote',
      key: 'quote',
    },
    {
      title: 'Products',
      key: 'product',
      icon: 'fe fe-download',
      url: '/product',
    },
    {
      title: 'Quotation',
      key: 'quotation',
      icon: 'fe fe-download',
      url: '/quotation',
    },
    {
      category: true,
      title: 'Accounts',
      key: 'accounts',
    },
    {
      title: 'Invoices',
      key: 'invoice',
      icon: 'fe fe-settings',
      url: '/invoice',
    },
    {
      title: 'Payments',
      key: 'payments',
      icon: 'fe fe-settings',
      url: '/payments',
    },
    {
      category: true,
      title: 'Enquiry',
      key: 'enquiry',
    },
    {
      title: 'Enquiry',
      key: 'enquiry',
      icon: 'fe fe-globe',
      url: '/enquiry',
    },
    {
      category: true,
      title: 'Subscrptions',
      key: 'subscrptions',
    },
    {
      title: 'Renewals',
      key: 'renewals',
      icon: 'fe fe-calendar',
      url: '/renewals',
    },
    {
      title: 'Services',
      key: 'services',
      icon: 'fe fe-calendar',
      url: '/services',
    },
    {
      title: 'Daily Reports',
      key: 'daily-reports',
      icon: 'fe fe-file',
      url: '/daily-reports',
    },
  ]
}
