const actions = {
  SET_STATE: 'dailyreports/SET_STATE',

  SHOW_CREATE: 'dailyreports/SHOW_CREATE',
  HIDE_CREATE: 'dailyreports/HIDE_CREATE',
  ADD_DAILY_REPORTS: 'dailyreports/ADD_DAILY_REPORTS',
  LOAD_DAILY_REPORT_USER: 'dailyreports/LOAD_DAILY_REPORT_USER',

  LOAD_ALL: 'dailyreports/LOAD_ALL',
}

export default actions
