/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  searchleadfollowup: null,
  searchaccountsfollowup: null,
  searchinvoicefollowup: null,
  servicefollowupinfo: null,
  invoicefollowupinfo: null,
  displayLeadFollowup: false,
  displayServiceFollowup: false,
  displayInvoiceFollowup: false,
  displayAccountsFollowup: false,
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_LEAD_FOLLOWUP:
      return { ...state, selectedData: action.payload, displayLeadFollowup: true }
    case actions.HIDE_LEAD_FOLLOWUP:
      return { ...state, selectedData: null, displayLeadFollowup: false }
    case actions.SHOW_SERVICE_FOLLOWUP:
      return { ...state, selectedData: action.payload, displayServiceFollowup: true }
    case actions.HIDE_SERVICE_FOLLOWUP:
      return { ...state, selectedData: null, displayServiceFollowup: false }
    case actions.SHOW_INVOICE_FOLLOWUP:
      return { ...state, selectedData: action.payload, displayInvoiceFollowup: true }
    case actions.HIDE_INVOICE_FOLLOWUP:
      return { ...state, selectedData: null, displayInvoiceFollowup: false }
    case actions.SHOW_ACCOUNTS_FOLLOWUP:
      return { ...state, selectedData: action.payload, displayAccountsFollowup: true }
    case actions.HIDE_ACCOUNTS_FOLLOWUP:
      return { ...state, selectedData: null, displayAccountsFollowup: false }
    default:
      return state
  }
}
