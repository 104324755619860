import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallservices() {
  return apiClient
    .get(`${BASE_URL}/getservices`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveserviceinfo(payload) {
  return apiClient
    .post(`${BASE_URL}/saveservicedata`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function changeservicevalidity(payload) {
  return apiClient
    .post(`${BASE_URL}/changeservicevalidity`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function addservicefollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/addservicefollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
